import React, { useContext, useEffect, useState } from "react"

import Layout from "../Layout/Layout"
import CheckInForm from "../../components/NoCodeForm/CheckInForm"

import { GlobalContext } from "../../context/GlobalContext"
import { getProviderByID } from "../../functions/database"

import { CircularProgress, ThemeProvider } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"

export const isBrowser = () => typeof window !== "undefined"

const Home = () => {
  const {
    loading,
    setLoading,
    setOrganization,
    providerID,
    db,
    primaryColor,
    setPrimaryColor,
    secondaryColor,
    setSecondaryColor,
    setLanguage,
    setProviderForm,
    setFilteredProviderForm,
  } = useContext(GlobalContext)

  const [providerInfo, setProviderInfo] = useState(null)

  //Load language from local storage ** potentially move to context **
  useEffect(() => {
    setLoading(true)

    if (isBrowser()) {
      const language = localStorage.getItem("language")
      if (language) {
        setLanguage(language)
      } else {
        setLanguage("en")
      }
    }
  }, [])

  //Utility function to handle legacy forms that use the formData sub object
  const flattenFormData = entries => {
    return entries.map(entry => {
      if (entry.formData) {
        // Merge main object and formData
        const mergedEntry = { ...entry, ...entry.formData }
        // Delete the formData property
        delete mergedEntry.formData
        return mergedEntry
      }
      return entry
    })
  }

  //Load provider info from database
  useEffect(() => {
    const fetchProviderInfo = async () => {
      try {
        const result = await getProviderByID(db, providerID)
        setProviderInfo(result)
        if (result) {
          setOrganization(result?.AccountName?.S)
          const formData = flattenFormData(JSON.parse(result?.Form?.S))
          setProviderForm(formData)
          setFilteredProviderForm(formData)
        }

        // providerInfo.Colors.Primary.S &&
        //   setPrimaryColor(providerInfo.Colors.Primary.S)
        // providerInfo.Colors.Secondary.S &&
        //   setPrimaryColor(providerInfo.Colors.Secondary.S)
        // setSecondaryColor(providerInfo.secondaryColor)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    if (db && providerID) {
      fetchProviderInfo()
    }
  }, [db, providerID])

  //Create theme from provider colors
  const theme = createTheme({
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            margin: 0,
            padding: 0,
          },
          body: {
            margin: 0,
            padding: 0,
            backgroundColor: "#f5f5f5",
          },
        },
      },
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  })

  if (loading) {
    return (
      <div style={{ fontSize: "15rem" }}>
        <CircularProgress />
      </div>
    )
  } else if (!loading && !providerInfo) {
    return <div>Error Retrieving Checkin Form</div>
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <CheckInForm />
      </Layout>
    </ThemeProvider>
  )
}

export default Home
