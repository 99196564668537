import { TranslateTextCommand } from '@aws-sdk/client-translate'

// Iterate over the provider form and create a string representing all text to be translated
const generateTranslateString = (formState) => {
  if (formState) {
    const toTranslate = []

    formState.forEach((question) => {
      toTranslate.push(question.question)
      question.choices && toTranslate.push(question.choices)
      question.helperText && toTranslate.push(question.helperText)
    })

    return toTranslate.join('🧵')
  }
  return ''
}

// Iterate over the provider form and replace
const replaceTranslatedText = (translatedFormState, translatedTextArray) => {
  translatedFormState.forEach((question) => {
    question.question = translatedTextArray.shift()
    question.choices && (question.choices = translatedTextArray.shift())
    question.helperText && (question.helperText = translatedTextArray.shift())
  })
}

// Main function for form translation
// Accepts the current form state and the target language code as parameters and returns the translated formState
export const translateProviderForm = async (
  translator,
  formState,
  targetLanguage
) => {
  try {
    const translateString = generateTranslateString(formState)

    if (!translateString) {
      return formState
    }

    const command = new TranslateTextCommand({
      SourceLanguageCode: 'auto',
      TargetLanguageCode: targetLanguage,
      Text: translateString
    })

    const response = await translator.send(command)
    const translatedText = response.TranslatedText

    // Create a deep copy of formState so we are not modifying the original form
    const translatedFormState = JSON.parse(JSON.stringify(formState))

    // Split translated string into an array
    replaceTranslatedText(translatedFormState, translatedText.split('🧵'))

    return translatedFormState
  } catch (err) {
    throw err
  }
}
