import { contactFieldIds, contactFieldKeys } from "../constants/contactFieldIds"
import defaultQuestionsLibrary from "../constants/defaultQuestionsLibrary"

export const generateContactID = (lastName, dob, providerID) => {
  return (
    `${lastName
      .replace(/\s/g, "")
      .replace(/[^a-zA-Z0-9]/g, "_")}-${dob}-${providerID}` || ""
  )
}

export const getAnswerFromSubmittedData = submittedData => {
  if (!submittedData) return {}
  return Object.keys(submittedData).reduce((vals, fieldId, i) => {
    if (contactFieldIds.indexOf(fieldId) > -1) {
      vals[contactFieldKeys[fieldId]] = submittedData[fieldId].value
    }
    return vals
  }, {})
}

export const isAnswered = (formState, questionID) => {
//   console.log("formState", formState)
//   console.log("questionID", questionID)
//   console.log("formState[questionID]", formState[questionID])
  if (
    formState[questionID]?.value === undefined ||
    formState[questionID]?.value === "" ||
    formState[questionID]?.value === null ||
    (Array.isArray(formState[questionID]?.value) &&
      formState[questionID]?.value.length === 0)
  ) {
    return false
  } else {
    return true
  }
}

export const containsOneTimeQuestions = providerForm => {
  // console.log(providerForm)
  let response = false
  providerForm.forEach(question => {
    if (question.frequency === "firstTime") {
      response = true
    }
  })

  return response
}

export const filterOneTimeQuestions = providerForm => {
  const filteredProviderForm = providerForm.reduce((prev, curr, i) => {
    //Default to rendering all questions
    let shouldRender = true

    //curr question should only be rendered on first visit and this is not a new user, set rendering to false
    if (curr?.frequency === "firstTime") {
      shouldRender = false
    }

    if (shouldRender) {
      prev.push(curr)
    }
    return prev
  }, [])

  return filteredProviderForm
}
