import React, { useContext } from "react"
import { Helmet } from "react-helmet"

import { GlobalContext } from "../../context/GlobalContext"

const SEO = () => {
  const { organization, language } = useContext(GlobalContext)

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={`${organization} | Check-in`}
      meta={[
        {
          name: `${organization} | Check-in`,
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0",
        },
      ]}
    />
  )
}

export default SEO
