import React, { useState, useRef, useEffect } from 'react'
import {
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Button,
  Radio,
  Typography,
  FormHelperText,
  RadioGroup,
  TextareaAutosize,
  Input,
  Tooltip,
  IconButton
} from '@material-ui/core'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { makeStyles } from '@material-ui/core/styles'
import { Rating } from '@material-ui/lab'

import CloseIcon from '@mui/icons-material/Close'
import Axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  group: {
    margin: theme.spacing(1, 0)
  },
  textInput: {
    width: '100%',
    fontSize: '1.25rem',
    paddingTop: '1rem'
  },
  helperText: {
    padding: '1rem'
  },
  rating: {
    justifyContent: 'center',
    fontSize: '3rem'
  },
  textArea: {
    fontFamily: 'Helvetica',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    backgroundColor: '#f2efef',
    width: '100%'
  },
  formCentered: {
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

//select appropriate initial value for single questions vs multiple choice
const getInitialValue = (fieldType, answer) => {
  if (answer) return answer.value

  let initialValue
  switch (fieldType) {
    case 'multiple choice (select many)':
    case 'multiple choice (select one)':
      initialValue = []
      break
    default:
      initialValue = null
      break
  }
  return initialValue
}
let country
export default function CheckInFormField(props) {
  useEffect(() => {
    Axios.get('https://api.hostip.info/country.php')
      .then((res) => {
        country = res.data?.toLowerCase()
      })
      .catch((err) => {
        console.log('Request failed')
      })
  }, [])
  const classes = useStyles()
  const { question, answer, onFieldChange, id } = props

  // Access type from formData
  const fieldType = safeLower(question.type)
  const fieldLabelText = question.question

  // Set initial value based on type
  const [value, setValue] = useState(getInitialValue(fieldType, answer))

  // Create a ref for the date input
  const dateInputRef = useRef(null)

  //For boolean checkboxes, not interacting with the checkbox should set the value to false
  useEffect(() => {
    if (fieldType === 'checkbox') {
      setValue(false)
      handleChange(null, false)
    }
  }, [fieldType])

  const handleChange = (event, value) => {
    // Check if the current input is the date input
    if (event?.target === dateInputRef.current) {
      // If it is, just update the ref's value
      dateInputRef.current.value = value
    } else {
      // Otherwise, perform the usual state update
      const fieldDataToSave = {
        id: question.questionID,
        value: value,
        fieldLabelText,
        fieldType
      }
      onFieldChange(fieldDataToSave)
    }
  }

  const formatPhoneNumber = (inputValue) => {
    // Remove all non-numeric characters
    let cleaned = ('' + inputValue).replace(/\D/g, '')

    // Match groups for country code, area code, central office code, and line number
    let match = cleaned.match(/^(\d{0,3})?(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      let formattedNumber = ''

      // country code
      if (match[1]) {
        formattedNumber += '+' + match[1] + ' '
      }
      //area code
      formattedNumber += '(' + match[2] + ') '
      formattedNumber += match[3] + '-' + match[4]
      return formattedNumber
    }

    //Phone number is not formatted correctly, we return here but this should be used for validation
    return inputValue
  }

  if (!fieldLabelText)
    return <div>Please enter a field label in the edit question section.</div>
  if (!fieldType)
    return <div>Please select an answer type in the edit question section.</div>

  return (
    <>
      <FormControl
        className={classes.formCentered}
        fullWidth
        key={question.questionID}
      >
        <Typography variant='h6'>{fieldLabelText}</Typography>

        {fieldType === 'text' || fieldType === 'address' ? (
          <TextField
            key={id}
            fullWidth
            autoFocus
            id={`${question.questionID}`}
            type='text'
            value={value || ''}
            autoComplete='off'
            onChange={(event) => {
              setValue(event.target.value)
              handleChange(event, event.target.value)
            }}
            inputProps={{ style: { textAlign: 'center' } }}
          />
        ) : null}

        {fieldType === 'number' ? (
          <TextField
            key={id}
            fullWidth
            autoFocus
            id={`${question.questionID}`}
            type='number'
            value={value || ''}
            autoComplete='off'
            onChange={(event) => {
              setValue(event.target.value)
              handleChange(event, event.target.value)
            }}
            inputProps={{ style: { textAlign: 'center' } }}
          />
        ) : null}

        {fieldType === 'email' ? (
          <TextField
            key={id}
            fullWidth
            autoFocus
            id={`${question.questionID}`}
            type='email'
            value={value || ''}
            autoComplete='off'
            onChange={(event) => {
              setValue(event.target.value)
              handleChange(event, event.target.value)
            }}
            inputProps={{ style: { textAlign: 'center' } }}
          />
        ) : null}

        {fieldType === 'feedback' ? (
          <TextareaAutosize
            className={classes.textArea}
            key={id}
            fullWidth
            autoFocus
            id={`${question.questionID}`}
            type='textarea'
            value={value || ''}
            autoComplete='off'
            onChange={(event) => {
              setValue(event.target.value)
              handleChange(event, event.target.value)
            }}
          />
        ) : null}

        {fieldType === 'date' ? (
          <TextField
            autoFocus
            key={id}
            fullWidth
            id={`${question.questionID}`}
            type='date'
            value={value || ''}
            autoComplete='off'
            onChange={(event) => {
              setValue(event.target.value)
              handleChange(event, event.target.value)
            }}
            inputProps={{ style: { textAlign: 'center' } }}
          />
        ) : null}

        {fieldType === 'phone number' ? (
          <PhoneInput
            style={{ width: 'auto' }}
            specialLabel={false}
            masks={{ hn: '.... ....' }}
            value={value}
            country={country || 'us'}
            onChange={(phone) => {
              setValue(phone)
              handleChange(null, phone)
            }}
          />
        ) : // <TextField
        //   key={id}
        //   fullWidth
        //   autoFocus
        //   id={`${question.questionID}`}
        //   type='tel'
        //   value={value || ''}
        //   autoComplete='off'
        //   onChange={(event) => {
        //     let formattedValue = formatPhoneNumber(event.target.value)
        //     setValue(formattedValue)
        //     handleChange(event, formattedValue)
        //   }}
        //   inputProps={{ style: { textAlign: 'center' } }}
        // />
        null}

        {fieldType === 'checkbox' ? (
          <FormControlLabel
            id={question.questionID}
            value={fieldLabelText}
            control={
              <Checkbox
                color='primary'
                checked={value}
                onChange={(event) => {
                  setValue(event.target.checked)
                  handleChange(event, event.target.checked)
                }}
              />
            }
          />
        ) : null}

        {fieldType === 'multiple choice (select many)' ? (
          <MultipleChoiceCheckboxWidget
            question={question}
            value={value}
            setValue={setValue}
            onChange={handleChange}
            fieldLabelText={fieldLabelText}
          />
        ) : null}

        {fieldType === 'rating' ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Rating
              id={question.questionID}
              name={question.questionID}
              value={value || 0}
              className={classes.rating}
              onChange={(event, newValue) => {
                setValue(newValue)
                handleChange(event, newValue)
              }}
              precision={0.5}
            />
          </div>
        ) : null}

        {fieldType === 'multiple choice (select one)' ? (
          <MultipleChoiceRadioWidget
            question={question}
            value={value}
            setValue={setValue}
            onChange={handleChange}
            autoComplete='off'
            fieldLabelText={fieldLabelText}
          />
        ) : null}

        {fieldType === 'photo upload' ? (
          <PhotoUploadWidget
            question={question}
            value={value || ''}
            onChange={handleChange}
            autoComplete='off'
          />
        ) : null}

        <FormHelperText className={classes.helperText}>
          {question.helperText}
        </FormHelperText>
      </FormControl>
    </>
  )
}

function MultipleChoiceRadioWidget({
  onChange,
  fieldLabelText,
  value,
  setValue,
  question
}) {
  const { questionID, choices } = question

  const handleChange = (event) => {
    setValue(event.target.value)
    onChange(event, event.target.value)
  }

  if (!safeTrim(choices)) {
    return <div>Please enter the available choice options.</div>
  }

  const choices_array = choices.split('\n')
  return (
    //Set up a radio group with a radio button for each choice
    <RadioGroup
      aria-label={fieldLabelText}
      name={questionID}
      value={value}
      onChange={handleChange}
    >
      {choices_array.map((item, i) => {
        return (
          <FormControlLabel
            key={i}
            value={item}
            control={<Radio />}
            label={item}
          />
        )
      })}
    </RadioGroup>
  )
}

function MultipleChoiceCheckboxWidget({
  onChange,
  fieldLabelText,
  value,
  setValue,
  question
}) {
  const { questionID, choices } = question

  const handleChange = (event, item) => {
    const newValue = value.includes(item)
      ? value.filter((v) => v !== item)
      : [...value, item]
    setValue(newValue)
    onChange(event, newValue)
  }

  if (!safeTrim(choices)) {
    return <div>Please enter the available choice options.</div>
  }

  const choices_array = choices.split('\n')
  return (
    <FormGroup>
      {choices_array.map((item, i) => {
        return (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                checked={value?.includes(item) ? true : false}
                onChange={(event) => handleChange(event, item)}
                value={item}
              />
            }
            label={item}
          />
        )
      })}
    </FormGroup>
  )
}

function PhotoUploadWidget({ onChange, fieldLabelText, value, question }) {
  // State to store the selected/uploaded image
  const [image, setImage] = useState(value)

  // Function to handle file selection
  const handleCapture = ({ target }) => {
    const fileReader = new FileReader()
    const file = target.files[0]

    if (file) {
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        setImage(fileReader.result)
        if (onChange) {
          onChange(null, fileReader.result)
        }
      }
    }
  }

  // Function to clear the selected image
  const handleClearImage = () => {
    setImage(null)
    if (onChange) {
      onChange(null) // Update external state if needed
    }
  }

  return (
    <FormControl>
      <FormLabel>{fieldLabelText}</FormLabel>
      {/* Invisible file input triggered by MUI button click */}
      <input
        accept='image/*'
        style={{ display: 'none' }}
        id='icon-button-file'
        type='file'
        onChange={handleCapture}
      />
      {image && (
        <div style={{ position: 'relative', marginTop: 20 }}>
          <img
            src={image}
            alt='Uploaded'
            style={{ maxWidth: '100%', height: 'auto' }}
          />
          <Tooltip title='Remove photo'>
            <IconButton
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                color: 'rgba(255, 255, 255, 0.8)', // White with some transparency
                backgroundColor: 'rgba(0, 0, 0, 0.4)' // Semi-transparent black
              }}
              onClick={handleClearImage}
              aria-label='delete'
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Button
        variant='contained'
        component='span'
        onClick={() => document.getElementById('icon-button-file').click()}
      >
        Take/Upload Photo
      </Button>
    </FormControl>
  )
}

function safeTrim(value) {
  return value?.trim() ?? ''
}

function safeLower(value) {
  return value?.toLowerCase() ?? ''
}
