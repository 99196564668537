export default {
  idQuestions: [
    {
      questionID: 'eb872789-54f6-4f09-a6c4-274965638f49',
      canRemove: false,
      canModify: false,
      translatable: false,
      frequency: 'everytime',
      isIDQuestion: true,
      isRequired: true,
      type: 'Text',
      question: 'Your last name',
      helperText: '',
    },
    {
      questionID: 'bd8da908-4f53-48a8-922f-855f56908a8f',
      canRemove: false,
      canModify: false,
      translatable: false,
      frequency: 'everytime',
      isIDQuestion: true,
      isRequired: true,
      type: 'Date',
      question: 'Your date of birth',
      helperText: '',
    },
  ],
  questions: [
    {
      questionID: 'ddb57059-752a-4764-9022-a7a5f468d9b0',
      canRemove: false,
      canModify: false,
      translatable: false,
      frequency: 'everytime',
      isIDQuestion: false,
      isRequired: false,
      type: 'Phone Number',
      question: 'Your cell phone number',
      helperText: '',
    },
    {
      questionID: '94d34f16-93da-4d35-8d4b-71f038cf0c91',
      canRemove: false,
      canModify: false,
      translatable: false,
      frequency: 'everytime',
      isIDQuestion: false,
      isRequired: false,
      type: 'Rating',
      question: 'Rate your experience here',
      helperText: '',
    },
  ],
  options: {
    submit_text: 'Submit',
    theme: {
      brand_logo: '',
      primary_color: '',
      secondary_color: '',
      default_color: '',
      disabled_color: '',
    },
  },
}
