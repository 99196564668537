import React from "react"

import { CssBaseline } from "@material-ui/core"

import { GlobalProvider } from "../context/GlobalContext"
import Home from "../components/Home/Home"
import SEO from "../components/SEO/SEO"


const App = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <GlobalProvider>
        <SEO />
        <Home />
      </GlobalProvider>
    </React.Fragment>
  )
}

export default App
