import { Button, Typography } from '@material-ui/core'
import React from 'react'
import './CheckInThankYou.css'

export default function CheckInThankYou({previewMode, handleReturn}) {
  return (
    previewMode ? (<div className='check-in-thank-you'>
        <Typography>You have reached the end of your form preview. This checkin data will <strong>not</strong> be saved.</Typography>
        {<Button color="primary" onClick={handleReturn}>Return to Form Editor</Button>}
    </div>) : (<div className='check-in-thank-you'>
        <Typography variant="h3">Thank You!</Typography>
        {<Button color="primary" onClick={handleReturn}>Close</Button>}
    </div>)
  )
}
